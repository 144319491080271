footer{
	.blue{
		padding: 25px 0;
		color: #fff;
		font-size: rem-calc(20);
		line-height: rem-calc(24);
	}

	.zoom{
		overflow: visible!important;
	}

	.part1{
		position: relative;
		padding-top: 20px;
		ul{
			margin-bottom: 0;
			margin-left: 0;
			margin: 0;
			width: 100%;
			text-align: center;
			display: inline-block;
			vertical-align: middle;
			overflow: visible; 
			li{
				display: inline-block;
				vertical-align: middle;
				float: none;
				margin-left: 0;
				&+li{
					margin-left: 50px;
				}
				a{
					svg{
						vertical-align: middle;
					}
					img{

					}
				}
			}
		}
	}
	.part2{
		padding-top: 10px;
		form{

			.large-8{
				padding: 0;
			}
			.large-4{
				padding: 0;
			}

			input{
				height: 44px;
				border-radius: 4px;
				// margin-right: 5px;
				box-shadow: none;
				font-size: rem-calc(15);
			}
			::-webkit-input-placeholder{
				color: $blue;
				font-size: rem-calc(15);
			}	
			:-moz-placeholder{
				color: $blue;
				font-size: rem-calc(15);
			}	
			::-moz-placeholder{
				color: $blue;
				font-size: rem-calc(15);
			}	
			:-ms-input-placeholder{
				color: $blue;
				font-size: rem-calc(15);
			}			
			button{
				width: 100%;
				height: 44px;
				padding: 0;
				background: #2b5899;
				color: #fff;
				border: none;
				border-radius: 4px;
				&:hover{
					color: #2b5899;
					background: #fff;
				}
			}
		}
	}

	.footer-menu{
		.submenu-list{
			display: none;
		}

	}
}

@media #{$small-up} { 

}
@media #{$small-only} { 

}

@media #{$medium-up} { 

}
@media #{$medium-only} { 

}

@media #{$large-up} { 

	footer{
		.footer-menu{
			text-align: center;
			.submenu-list{
				display: inline-block;
				li{
					a{
						color: #717275;
						&:hover{
							color: $blue;
						}
					}
				}
			}	
		}

		.part1, .part2{
			height: 60px;
			line-height: 60px;
			padding-top: 0;
			.row.collapse{
				width: 100%;
			}
			p{
				margin-bottom: 0;
				font-size: rem-calc(16);
				line-height: rem-calc(20);
			}
		}

		.part1{
			&:after{
				border-right: 1px solid #cccccc;
				content: "";
				position: absolute;
				right: 0;
				height: 60px;
				top: 50%;
				margin-top: -30px;
			}
			>div{
				padding-left: 30px;
				padding-right: 50px;				
				>div{
					width: 50%;
					text-align: center;
				}
			}
			ul{
				margin-left: 30px;
				li{
					&+li{
						margin-left: 50px;
					}					
				}
			}			
		}
		.part2{
			>div{
				padding-left: 25px;
				padding-right: 25px;
			}				
			form{
				.large-8{
					padding-left: 15px;
				}
				.large-4{
					padding-left: 15px;
				}				
				input{
					vertical-align: middle;
					display: inline-block;
					margin-bottom: 0;
				}
				button{
					width: 44px;
					vertical-align: middle;
					margin-bottom: 0;
				}
			}
			p{
				margin-bottom: 0;
			}
		}

	}	

}
@media #{$large-only} { 

}

@media #{$xlarge-up} { 

	footer{


		.part1, .part2{
			p{
				font-size: rem-calc(20);
				line-height: rem-calc(24);
			}
		}

		.part1{
			>div{
				padding-left: 40px;
				padding-right: 80px;
			}
			ul{
				margin-left: 30px;
				li{
					&+li{
						margin-left: 50px;
					}					
				}
			}
		}
		.part2{
			>div{
				padding-left: 80px;
				padding-right: 40px;
			}		
		}	
	}

}
@media #{$xlarge-only} { 

}

@media #{$xxlarge-up} { 

}
@media #{$xxlarge-only} { 

}

