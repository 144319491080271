.page-template-template-carto, .single-ligne{

	main{
		.content{		
			strong{
				background: none;
				font-weight: 700;
			}
		}
	}

	.line-info{
		// padding-top: 35px;
		padding-bottom: 35px;
		.title-choice{
			br{
				display: none;
			}
			font-size: rem-calc(20);
			line-height: rem-calc(24);
			font-weight: 900;			
		}
		#map{
			margin-bottom: 20px;
			height: 400px;
		}
	}


	.autres-infos{
		margin-top: 20px;
		p{
			&.aside-title{
				margin-top: 20px;
				margin-bottom: 20px;
			}
			// font-size: rem-calc(16);
			line-height: rem-calc(24);
			margin-bottom: 25px;			
			span{
				text-transform: uppercase;
				font-size: rem-calc(15);
				line-height: rem-calc(18);		
				color: #828487;		
			}
		}
	}

	.aside-content{
		.btn{
			display: block;
			padding-left: 60px;
			padding-right: 10px;
		}

	}

	.btn-2{
		background: #fff;
	}

	.btn{
		margin-bottom: 0;
	}

}

.single-ligne{
	.section-arrow-ligne{
		margin-top: 40px;
		padding-bottom: 40px;
		.inside-container{
			text-align: center;
			>svg{
				margin-top: -2px;
				width: 60px;
				height: 28px;
			}
		}	
	}	
	.content{
		.btn{
			&:hover{
				// background: red;
			}
		}
	}
}

.single-post, .single-actualite{
	.cat-date{
		margin-bottom: 0;
		color: $grey;
		font-size: rem-calc(11);
		line-height: rem-calc(13);
		font-weight: 900;
		margin-bottom: 20px;
	}

	main{
		.content{
			.intro{
				p{
					text-align: left;
				}
			}
		}
	}	

	article{
		.social-share{
			margin: 0 0 25px;
			li{
				margin-left: 10px;
				margin-right: 10px;
				&:first-child {
					margin-left: 0;
				}
				
			}
		}

		.thumbnail{
			margin-bottom: 30px;
		}

		footer{
			.social-share{
				margin: 30px 0;
			}
		}
	}
	
}

.single-client, .single-chargeur{
	
	.section-arrow{
		padding-bottom: 20px;
	}

	article{
		overflow-x: hidden;
		h1{
			text-transform: uppercase;
			margin-top: 10px;
		}		
		p{
			&.intro{
				font-size: rem-calc(16);
				line-height: rem-calc(19.2);
				font-weight: 900;
			}			
			&.signature{
				font-size: rem-calc(16);
				line-height: rem-calc(19.2);
				font-weight: 700;
				color: $blue;
				margin-top: -15px;
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 50px;
				span{
					color: $grey;
					font-weight: 400;
					text-transform: uppercase;
				}
			}
		}

		.citation{
			&:before{
				margin-top: 0;
			}
			&:after{
				margin-top: 0;
				margin-bottom: 0;
			}			
		}

		.btn{
			margin: 0;
		}	
		.custom-menu{
			background: $blue;
			margin-top: 20px;
			a{
				position: relative;
				display: block;
				font-size: rem-calc(15);
				line-height: rem-calc(18);			
				text-transform: uppercase;
				padding: 15px 20px;
				font-weight: 900;
				span{
					color: #fff;
				}
				svg{
					position: absolute;
					right: 20px;
				}
			}
			.tabs-custom{
				transition: height 3s ease;
				height: 0;
				overflow: hidden;
				&.open{
					height: auto;
				}
				li{
					display: block;
					float: none;
					&.active{
						display: none;
						a{
							background: $blue;
						}
						// a{
						// 	color: #c3c3c3;
						// }
					}
					a{
						background: #77a5e6;
						display: block;
						color: #fff;
						font-weight: 900;
					}
				}
			}
		}
		.tabs-content{
			margin-bottom: 0;
			h2{
				margin-top: 0;
			}
		}
	}
	.main section{
		padding-top: 0;
	}
}

.single-galerie{

	.section-arrow{
		padding-bottom: 35px;
	}

	.clearing-thumbs{
		// margin-top: 30px;
		width: 100%;
		li{
			padding-left: 0;
			padding-right: 0;
			text-align: center;
			a{
				display: inline-block;
				p{
					font-size: rem-calc(14);
					line-height: rem-calc(16.8);
					font-weight: 500;
					color: $grey;
					margin-top: 10px;
					text-align: left;
				}
			}
		}
	}
}

@media #{$small-up} { 

}

@media #{$small-only} { 

}

@media screen and (min-width: 768px) {
	.single-galerie{
		.clearing-thumbs{
			li{
				width: 50%;
				&:nth-of-type(1n+1){
					clear: none;
				}
			}
		}
	}
}

@media #{$medium-up} { 


	.single-galerie{

		.section-arrow{
			.inside-container{
				&:before{
					background-position: center top;
				}
			}
		}	

		.clearing-thumbs{
			// margin-left: -15px;
			// margin-right: -15px;
			li{
				padding-left: 15px;
				padding-right: 15px;
				width: 25%;
				a{
					p{
						font-size: rem-calc(15);
						line-height: rem-calc(18);
						margin-top: 10px;
					}
				}
			}
		}
	}

	.page-template-template-carto, .single-ligne{

		.mt-single{
			margin-top: 35px;
		}

		.btn{
			svg{
				max-width: 32px;
			}
		}

		.line-info{
			.title-choice{
				br{
					display: inline;
				}
				font-size: rem-calc(50);
				line-height: rem-calc(53);
			}	
			#map{
				height: 576px;
			}
		}

		.aside-content{
			float: right;
		}

	}

	.single-ligne{
		.section-arrow-ligne{
			.inside-container{
				text-align: left;
				>svg{
					width: 73px;
					height: 34px;
				}
			}	
		}	
	}


	.single-post, .single-actualite{

		h1{
			font-size: rem-calc(40);
			line-height: rem-calc(53);
			margin-bottom: 40px;
		}
		.cat-date{
			font-size: rem-calc(16);
			line-height: rem-calc(19);
			margin-bottom: 20px;
		}
		
		main{
			.content{
				.intro{
					p{
						font-size: rem-calc(18);
						line-height: rem-calc(30);
					}
				}
			}
		}

		article{
			.social-share{
				ul{
					margin: 0;
				}
			}

			.thumbnail{
				margin-bottom: 30px;
			}

			footer{
				.social-share{
					margin-top: 30px;
					// ul{
					// 	margin-left: 0;
					// 	margin-right: auto;
					// }
				}
			}
		}
		
	}	

	.single-client, .single-chargeur{

		.section-arrow{
			padding-bottom: 35px;
		}		

		article{

			h1{
				margin: 0;
			}

			p{
				&.intro{
					font-size: rem-calc(40);
					line-height: rem-calc(53);
				}			
			}	
			.custom-menu{
				background: none;
				margin-top: 40px;
				margin-left: -55px;
				margin-right: -55px;
				a{
					&.burger{
						display: none;
					}
				}
				.tabs-custom{
					display: table!important;
					width: 100%;
					height: auto;
	 				border-spacing: 55px 0;
	    			border-collapse: separate;		
	    			&:before, &:after{
	    				content: none;
	    			}		
					li{
						background: none;
						display: table-cell;
						float: none;
						width: 33.333333333%;
						&.active, &:hover{
							display: table-cell;
							&.tab-1, &.tab-2, &.tab-3{
								a{
									background: $blue;
									&:before{
										border-color: transparent $blue transparent transparent;
									}
									&:after{
										border-color: $blue transparent transparent transparent;
									}
								}
							}
						}
						&.tab-1{
							text-align: left;
							a{
								&:after{
									@include top-right(60px, 15px);
									border-color: #77a5e6 transparent transparent transparent;
								}
							}
						}
						&.tab-2{
							text-align: center;
							a{
								&:before{
									@include bottom-left(60px, 15px);
									border-color: transparent #77a5e6 transparent transparent;
								}							
								&:after{
									@include top-right(60px, 15px);
									border-color: #77a5e6 transparent transparent transparent;
								}
							}						
						}	
						&.tab-3{
							text-align: right;
							a{
								&:before{
									@include bottom-left(60px, 15px);
									border-color: transparent #77a5e6 transparent transparent;
								}
							}						
						}										
						a{
							background: #77a5e6;
							display: block;
							color: #fff;
							text-align: center;
							width: 100%;
							padding: 0;
							line-height: 60px;					
						}
					}
				}
			}

			.tabs-content{
				margin-top: 60px;
			}
		}	

	}	

}
@media #{$medium-only} { 

}

@media #{$large-up} { 


	.single-client, .single-chargeur{

		article{

			.citation{

				&:before{
					content: url(../images/citation-t-2x.svg);
					display: block;
					margin-left: -50px;
					margin-top: 0;
					margin-bottom: -15px;
				}
				&:after{
					content: url(../images/citation-b-2x.svg);
					display: block;
					text-align: right;
					margin-top: -25px;
					margin-bottom: 0;
					margin-right: -50px;
				}			
			}
		}
	}

}

@media #{$large-only} { 

}

@media #{$xlarge-up} { 

	
}
@media #{$xlarge-only} { 

}

@media #{$xxlarge-up} { 

}
@media #{$xxlarge-only} { 

}