body, h1, h2, h3, h4, h5{
	font-family: 'Roboto', sans-serif;
	color: $grey;
	font-size: 18px;
}

section, div{
	&.grey{
		background-color: #f3f3f3;
	}
	&.end-section{
		padding-bottom: 80px;
	}	
	&.blue{
		background-color: $blue;
	}
}

a, button{
	&:focus {outline:none;}
}

.page-template-default, .page-template-template-blog, .page-template-template-faq, .single-ligne{
	footer{
		margin-top: 80px;
	}
}

.clearfix.divider + h2{
	margin-top: 30px;
}

.no-touch{
	.zoom{
		display: block;
		overflow: hidden;
		text-align: center;
		img, svg{
			transition: all .2s ease-in-out;
		    -webkit-transform: none; 
		    -moz-transform: none;
		    -o-transform: none;
		    transform: none;			
		}
		&:hover{
			svg, img{
			    -webkit-transform: scale(1.3); 
			    -moz-transform: scale(1.3);
			    -o-transform: scale(1.3);
			    transform: scale(1.3);		
			}
		}
	}
}

.social-share{
	>ul{
		list-style: none;
    	display: table;
    	margin: 0 auto;
		li{
			&:before{
				content: none!important;
			}	
			float: left;
			margin-left: 1px;
			margin-right: 1px;
			width: 40px;
			line-height: rem-calc(40);
			background: $bluefade;
			text-align: center;
			transition: background-color 0.3s ease;

			&:first-child{
				margin-left: 0;
			}
			&:hover{
				background: $blue;
			}
			a{
				color: #fff;
				display: block;
				svg{
					vertical-align: middle;
				}
			}
		}
	}
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.section-arrow{
	margin-top: 30px;
	padding-bottom: 50px;
	.inside-container{
		&:before{
			background-repeat: no-repeat;
			background-position: center top;
			display: block;
			content: "";
			margin-top: -2px;
			height: 28px;
			background-image: url(../images/fleche-blanc-mobile.png);
		}
	}	
}

.page-header{
	z-index: 15;
	position: relative;
	margin-left: -15px;
	margin-right: -15px;
	margin-top: -15px;		
	margin-bottom: 15px;
	h1, .title-h1{
		padding-left: 15px;
		padding-right: 30px;
		margin-top: 0;
		background: $blue;
		display: inline-block;
		color: #fff;
		height: 40px;
		line-height: 40px;
		margin-bottom: 0;
		font-size: rem-calc(16);
		position: relative;
		&:after{
			@include top-right(40px, 15px);
		}
	}
}

.single-post, .search, .single-client, .single-chargeur, .single-galerie{
	h1{
		color: $blue;
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		font-weight: 900;
		margin-bottom: 10px;
	}
}

.search{
	h1{
		color: $grey;
	}
	h2{
		span{
			font-weight: 400;
			color: $grey;
		}
	}
	.entry-summary{
		a{
			p{
				color: $grey;
			}
		}
	}
}

.intro{
	p{
		font-weight: 700;
		color: $grey;
		font-size: rem-calc(15);
		line-height: rem-calc(22);
		text-align: center;
	}
}

main{
	.content{
		h2{
			line-height: rem-calc(24);
			font-weight: 500;
			margin-bottom: 0;
			font-size: rem-calc(18);
			color: $blue;
			margin-bottom: 30px;
			margin-top: 50px;
		}

		h3{
			line-height: rem-calc(22);
			font-weight: 500;
			margin-bottom: 0;
			font-size: rem-calc(16);
			color: $blue;
			margin-bottom: 30px;
			margin-top: 50px;
		}
		
		strong{
			background: #CFDFF6;
			font-weight: 400;
		}

		a:not(.btn):not(.btn-2){
			color: $blue;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
			&:active{
				color: inherit;
			}
		}

		.block{
			background: #f2f2f2;
			padding: 30px;
			margin-top: 30px;
			margin-bottom: 30px;
			border-left: 4px solid $blue;
			p{
				margin-top: 0;
				margin-bottom: 0;
				&+p{
					margin-top: 10px;
				}
			}
		}
		.citation{
			position: relative;
			&:before{
				content: url(../images/citation-t.svg);
				display: block;
				// margin-left: -15px;
				margin-top: 20px;
				margin-bottom: 10px;
			}
			&:after{
				content: url(../images/citation-b.svg);
				display: block;
				text-align: right;
				margin-top: 10px;
				margin-bottom: 20px;
				// margin-right: -15px;
			}			
			blockquote{
				border: none;
				font-size: rem-calc(16);
				line-height: rem-calc(28);
				color: $grey;
				padding: 0 15px;
				// span{
				&:first-letter{
					color: $blue;
					font-weight: 900;
					font-size: rem-calc(32);
				}
			}
		}
		ul:not(.tabs):not(.accordion):not(.informations):not(.equipements):not(.clearing-thumbs):not(.f-dropdown):not(.table-pictos):not(.in-social-share):not(.all-chiffres){
			li{
				font-size: rem-calc(14);
			}
			list-style-type: none;
			margin-bottom: 30px;
			li{
				margin: 7.5px 0;
				&:before{
					content: "/";
					font-weight: 900;
					margin-right: 5px;
					color: $blue;
				}
			}
		}

		ul{
			&.equipements{
				margin: 0;
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;				
				padding: 0 20px;
				li{
					text-align: center;
					&:before{
						content: none;
					}				
					svg, img{
						vertical-align: bottom;
					}
					p{
						margin-top: 5px;
						font-size: rem-calc(11);
						line-height: rem-calc(14);
						margin-bottom: 0;
					}
				}
			}

			&.informations{
				margin: 0;
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;				
				strong{
					color: $blue;	
					font-weight: 900;	
					background: none;								
				}				
				>li{
					&:before{
						content: none;
					}
					display: block;
					padding-top: 10px;
					padding-bottom: 10px;
					border-bottom: 1px solid #cacacb;
					.custom-table{
						display: table;
						width: 100%;
						>div{
							display: table-cell;
							vertical-align: middle;
							&.svg{
								width: 55px;
								padding-left: 10px;
								img, svg{
									width: 32px;
									vertical-align: middle;
								}
							}
							p{	
								margin: 0;
								line-height: rem-calc(18);
								font-size: rem-calc(14);
							}
						}
					}
				}
			}
		}

	}
}

.page-template-template-galerie-php, .single-galerie, .search{
	main{
		.content{
			a:not(.btn):not(.btn-2){
				text-decoration: none;
				&:hover{
					text-decoration: underline;
				}
				&:active{
					color: inherit;
				}
			}
		}
	}
}

p{
	font-size: rem-calc(14);
	line-height: rem-calc(24);
	&.title{
		font-size: rem-calc(20);
		line-height: rem-calc(24);
		font-weight: 900;
		color: $grey;	
		margin-bottom: 25px;	
	}
	&.sub-title{
		font-size: rem-calc(26);
		line-height: rem-calc(31.2);
		font-weight: 900;		
		text-transform: uppercase;
		margin-top: 50px;
		margin-bottom: 25px;
	}
	&.aside-title{
		font-size: rem-calc(20);
		line-height: rem-calc(24);
		font-weight: 900;		
		color: $grey;
		margin-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 15px;
		position: relative;
		border-bottom: 4px solid $blue;
	}	
	&.title-h1{
		
	}
}

.breadcrumbs{
	background: none;
	border: none;
	margin-left: 0;
	padding: 0;
	margin-bottom: 25px;
	line-height: 1;
	li{
		text-transform: none;
		color: $grey;
		font-size: rem-calc(11);
		line-height: 1;
		float: none;
		display: inline;
		&:before{
			content: ">";
			font-weight: 700;
			margin-left: 5px;
			margin-right: 5px;		
			top: 0;
		}
		a{
			color: $grey;
		}
	}
}

.clearing-main-prev, .clearing-main-next {
position: absolute;
height: 100%;
width: 40px;
top: 0; }
.clearing-main-prev > span, .clearing-main-next > span {
position: absolute;
top: 50%;
display: block;
width: 0;
height: 0;
border: solid 12px; }
.clearing-main-prev > span:hover, .clearing-main-next > span:hover {
opacity: 0.8; }
.clearing-main-prev {
left: 0; }
.clearing-main-prev > span {
left: 5px;
border-color: transparent;
border-right-color: #cccccc; }
.clearing-main-next {
right: 0; }
.clearing-main-next > span {
border-color: transparent;
border-left-color: #cccccc; }
.clearing-main-prev.disabled, .clearing-main-next.disabled {
opacity: 0.3; }

#cookie-law-info-bar{
	padding: 20px 60px 20px 50px;
	font-size: rem-calc(14);
	line-height: rem-calc(20);
	color: $grey!important;
	z-index: 99999999;
	a.cli-plugin-main-link{
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}
	#cookie_action_close_header{
		// margin-top: 10px;
		// display: block;
		background: none!important;
		box-shadow: none!important;
		border: none!important;
		display: inline;
		vertical-align: middle;
		position: absolute;
    	right: 0;
    	top: 20px;		
		&:after{
		content: url(../images/close-cookie.svg);
    	}
	}
}


@media #{$small-up} { 

}
@media #{$small-only} { 

}

@media #{$medium-up} { 

	.section-arrow{
		.inside-container{
			&:before{
				background-position: left top;
				height: 34px;
				background-image: url(../images/fleche-sep-blanc.png);
			}
		}	
	}

	.page-header{
		margin-top: -70px;	
		margin-left: 0;
		margin-right: 0;	
		margin-bottom: 20px;			
		h1, .title-h1{	
			padding-left: 80px;
			padding-right: 110px;
			color: #fff;
			height: 80px;
			line-height: 80px;
			font-size: rem-calc(38);
			min-width: 440px;
			&:after{
				@include top-right(80px, 35px);
			}
		}
	}


	.single-post, .search, .single-client, .single-chargeur, .single-galerie{
		h1{
			font-size: rem-calc(40);
			line-height: rem-calc(53);
			margin-bottom: 40px;
		}
	}


	.intro{
		margin-bottom: 30px;
		p{
			font-size: rem-calc(20);
			line-height: rem-calc(30);
		}
	}

	main{
		.content{
			h2{
				line-height: rem-calc(32);
				font-size: rem-calc(28);
				margin-bottom: 30px;
				margin-top: 50px;
			}

			h3{
				line-height: rem-calc(28);
				font-size: rem-calc(24);
				margin-bottom: 30px;
				margin-top: 50px;
			}

			ul:not(.tabs):not(.accordion):not(.informations):not(.equipements):not(.clearing-thumbs):not(.f-dropdown):not(.table-pictos):not(.in-social-share):not(.all-chiffres){
				li{
					font-size: rem-calc(18);
				}
			}		
			
			.citation{
				blockquote{
					font-size: rem-calc(20);
					line-height: rem-calc(30);
				}	
			}

			ul{
				&.informations{			
					>li{
						.custom-table{
							>div{
								&.svg{
									width: 65px;
									padding-left: 10px;
									padding-right: 25px;
									img, svg{
										vertical-align: middle;
										max-width: none;
										max-height: 38px;
									}
								}
								p{	
									line-height: rem-calc(22);
									font-size: rem-calc(20);
								}
							}
						}
					}
				}	
			}

			.table{
				display: table;
				width: 100%;
				>div{
					display: table-cell;
					width: 50%;			
					&.top{
						vertical-align: top;	
					}					
					&.middle{
						vertical-align: middle;	
					}
				}
			}

		}
	}	

	p{
		font-size: rem-calc(18);
		line-height: rem-calc(30);		
		&.title{
			font-size: rem-calc(50);
			line-height: rem-calc(53);
			margin-bottom: 25px;	
		}
		&.sub-title{
			font-size: rem-calc(26);
			line-height: rem-calc(31.2);
			margin-top: 50px;
			margin-bottom: 25px;
		}
		&.aside-title{
			font-size: rem-calc(27);
			line-height: rem-calc(32);
			margin-top: 0;
		}	
	}	

	.breadcrumbs{
		li{
			font-size: rem-calc(13);
			&:before{
				content: ">";
				font-size: rem-calc(13);
			}
		}
	}	

}
@media #{$medium-only} { 

}

@media #{$large-up} { 


	.valign-middle {
	    display: table;

	    > .columns {
	        display: table-cell;
	        vertical-align: middle;
	    }

	    > .columns, > [class*="column"] + [class*="column"]:last-child {
	        float: none;
	    }
	}

	.inside-container{
		position: relative;	
		margin: 0 80px;
		&.content{
			.small-12.medium-8.columns{
				max-width: 723px;
			}
			.small-12.medium-4.columns{
				max-width: 333px;
			}
		}
	}

	.inside-container-2{
		position: relative;	
		margin: 0 265px;
	}	


}

@media #{$large-only} { 

}

@media #{$xlarge-up} { 

	
}
@media #{$xlarge-only} { 

}

@media #{$xxlarge-up} { 

}
@media #{$xxlarge-only} { 

}