.home{
	.main{
		section{
			padding: 70px 0 30px;	
		}
	}

	.reveal-modal{
		padding-top: 70px; 
	}

}

.archive.date{
	footer{
		margin-top: 20px;
	}
}

.main{
	section{
		// padding: 70px 0 30px;	
		.inside-container{
			position: relative;
			&:before{
				transform: translateX(-50%);
				left: 50%;
			}
		}
		&.section-blog{
			padding-top: 20px;
			&.side{
				padding-top: 0;		
			}					
			.all-articles{
				>div{
					article{
						border-top: 1px solid #e6e6e6;
					}
					&+div{
						article{
							border-top: 0;
						}
					}
				}
				article{
					padding: 10px 0;
					border-bottom: 1px solid #e6e6e6;
					position: relative;
					a{
						color: inherit;
						&:hover{

						}
					}
					.img-blog{
						overflow: hidden;										
					}
					&:hover{
						.date, p{
							color: $blue;
						}						
					}

					.descriptif{		
						p{
							font-size: rem-calc(13);
							line-height: rem-calc(16);
							margin-bottom: 0;						
						}
						.date{
							display: block;
							color: $bluefade;
							font-weight: 900;
							font-size: rem-calc(10);
							margin-bottom: 10px;
						}
					}
					.social-share{
						display: none;
					}					
				}
			}
		}
		&.decouvrir-via{
			.inside-container{
				&:before{
					position: absolute;
					content: url(../images/fleche-sep-gris.png);
					top: -72px;
				}	
			}
			ul{
				li{
					text-align: left;
					div{
						display: table;
						table-layout: fixed;
						color: inherit;
						font-size: rem-calc(15);
						line-height: rem-calc(18);
						font-weight: 500;						
						span{
							display: table-cell;
							vertical-align: middle;							

						}
						img, svg{
							display: table-cell;
							vertical-align: middle;
							width: 56px;
							height: 56px;
							margin: 0 20px 0 0;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
		&.reseau{
			position: relative;
			background-image: url(../images/test/banniere-reseau.jpg);
			background-position: center 70px;
			padding-top: 50px;
			background-repeat: no-repeat;			
			background-size: contain;
			.inside-container{
				&:before{
					position: absolute;
					content: url(../images/fleche-sep-blanc.png);
					top: -52px;
				}	
			}

			.legende{
				display: none;
			}

			p.title{
				margin-bottom: 375px;
			}	

			.compteur{
				color: #fff;
				background-color: $blue;	
				z-index: 15;
				position: relative;
				bottom: -30px;
				svg, img{
					float: left;
					margin-right: 30px;
					width: 48px;
					height: 48px;
					vertical-align: middle;
				}
				>div{
					padding: 25px 15px;
					p{
						line-height: rem-calc(20);
						font-size: rem-calc(16);
						margin-bottom: 0;
						span{
							display: block;
							font-size: rem-calc(13);	
							color: #9ebeed;
						}
					}

					&:after{
						@include bottom-arrow-after();
					}	

				}	

				.number{
					clear: left;
					text-align: center;
					margin-top: 20px;
					span{
						text-align: center;
						&.odometer-digit{
							width: 26px;
							height: 44px;							
							margin: 0 2.5px;
							font-family: 'Roboto Condensed', sans-serif;
						    -moz-border-radius: 5px;
						    -webkit-border-radius: 5px;
						    -o-border-radius: 5px;
						    -ms-border-radius: 5px;
						    -khtml-border-radius: 5px;
						    border-radius: 5px;
							background: #2d5ed4;
							background: -moz-linear-gradient(top,  #2d5ed4 20%, #508bdf 54%, #2d5ed4 60%, #2d5ed4 100%);
							background: -webkit-linear-gradient(top,  #2d5ed4 20%,#508bdf 54%,#2d5ed4 60%,#2d5ed4 100%);
							background: linear-gradient(to bottom,  #2d5ed4 20%,#508bdf 54%,#2d5ed4 60%,#2d5ed4 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d5ed4', endColorstr='#2d5ed4',GradientType=0 );
							.odometer-value{
								font-size: rem-calc(32);
								font-weight: 800;
								line-height: 44px;
								text-align: center;
								left: 0;
								right: 0;								
							}							
						}	
						&.odometer-formatting-mark{
							margin: 0 1.5px;
						}								
					}		
				}		
			}
		}
		&.instagram{
			padding-top: 90px;
			padding-bottom: 30px;
			.list{
				a{
					display: block;
					overflow: hidden; 
				}
				span{
					color: #949598;
					font-size: rem-calc(13);
					line-height: rem-calc(16.8);
					font-weight: 900;
					margin-bottom: 10px;
					display: block;
				}
				p{
					font-size: rem-calc(14);
					line-height: rem-calc(16.8);
					font-weight: 500;
					margin-top: 10px;
					a{
						color: $grey;
					}
				}
				.apercu{
					position: relative;
					a{
						&:after{
							content: url(../images/picto-play-youtube.svg);
							position: absolute;
							top: 50%;
							left: 50%;
							margin-left: -24px;
							margin-top: -24px;
							height: 48px;
							width: 48px;
							cursor: pointer;
						}
					}
				}
			}
		}	
	}
}

.page{
	&.page-template-template-blog{
		background: #f3f3f3;
		.btn{
			margin-top: 0;
		}	
	}
	&.page-template-template-faq{
		.accordion{
			.active{
				.btn-2{
					img, svg{
						&.plus{
							display: none;
						}						
						&.moins{
							display: inline-block;
						}						
					}
				}			
			}
			.btn-2{
				margin-top: 20px;
				margin-bottom: 20px;
				color: $blue!important;
				position: relative;
				padding-right: 60px;
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: rem-calc(14);
				img, svg{
					position: absolute;
					right: 16px;
					top: 50%;
					margin-top: -12px;
					width: 24px;
					height: 24px;
					&.plus{
						display: inline-block;
					}
					&.moins{
						display: none;
					}
				}
			}
			.content{
				padding: 0 5px;
				p{
					font-size: rem-calc(14);
					line-height: rem-calc(24);
					margin-bottom: 0;
				}
			}
			&+p{
				margin-top: 20px;
				margin-bottom: 0;
				a{
					margin-bottom: 0;
				}
			}
		}
	}
	&.page-template-template-clients-php, &.page-template-template-chargeurs-php, &.page-template-template-terminaux-php, &.page-template-template-plus-viia-php{
		.accordion{
			 .accordion-navigation{
			 	margin: 20px 0; 
			 	>a{
			 		padding: 0;	
			 		background: none;
			 		&:hover{
			 			text-decoration: none;
			 			h2{
			 				background: #2763b9;
			 			}
			 		}
			 		h2{
			 			margin-top: 0;
			 			margin-bottom: 0;
			 			color: #fff;
			 			background: $blue;
						padding: 20px;
						font-size: rem-calc(16);
						font-weight: 900;
						position: relative;
						svg{
							position: absolute;
							right: 16px;
							top: 50%;
							margin-top: -12px;
							width: 24px;
							height: 24px;
							&.plus{
								display: inline-block;
							}
							&.moins{
								display: none;
							}
						}						
			 		}
			 	}
			 	&.active{
			 		>a{
			 			h2{
			 				background: #2763b9;
							img, svg{
								&.plus{
									display: none;
								}						
								&.moins{
									display: inline-block;
								}						
							}			 				
			 			}
			 		}
			 	}
			 }
			.content{
				padding: 0;
				.padding-10{
					padding: 20px;
				}	
			}		 
		}
	}
	&.page-template-template-clients-php, &.page-template-template-chargeurs-php{
		.accordion{
			.content{
				p{
					margin: 0;
					&:first-letter{
					font-weight: 700;
					color: $blue;
					}
				}
				.btn{
					margin: 0;
				}
			}	
		}	
	}
	&.page-template-template-terminaux-php{

		.section-arrow{
			padding-bottom: 0;
		}

		.all-terminaux{
			background: #f3f3f3;
			padding-top: 20px;
			padding-bottom: 70px;
		}
		.section-arrow-2{
			position: relative;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 10px;
			padding-bottom: 10px;
			background: #f3f3f3;
			.inside-container{
				&:before{
					position: absolute;
					content: none;
					top: -72px;
				}	
				.accordion{
					.accordion-navigation{
						margin: 0;
					}
				}
			}	
		}
		.informations{
			margin: 0;
			>li{
				>p{
					&.maj-gris{
						text-transform: uppercase;
						margin-bottom: 5px;
					}
					margin-bottom: 0;
					font-size: rem-calc(15);
					line-height: rem-calc(18);
				}
			}
			.equipements{
				margin-top: 20px;
			}
		}
		.btn{
			margin: 0;
		}	
		.table{
			.top{
				&:last-child{
					.informations{
						>li{
							border-bottom: 0;
							// padding-bottom: 0;
							li{
								padding-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}	
	&.page-template-template-plus-viia-php{
		.accordion{
			.accordion-navigation{
			 	margin: 20px 0!important; 
			 	&.active{
				 	>a{
				 		text-decoration: none;
			 			h2{
			 				background: none;
			 			}				 		
				 		&:hover, &:focus{
				 			text-decoration: none;
				 			h2{
				 				background: none;
				 			}
				 		}				 		
				 	}				 		
			 	}
			 	>a{
			 		position: relative;
			 		text-decoration: none;
			 		&:hover, &:focus{
			 			text-decoration: none;
			 			h2{
			 				background: none;
			 			}
			 		}			 		
			 		h2{
			 			color: $blue;	
			 			background: none;	
						padding-left: 50px;
						padding-right: 50px;
						svg{
							right: 0;
						}		
						&:after{
							border: none;
						}
			 		}
					img{
						width: 40px;
						height: 40px;
						top: 50%;
						margin-top: -20px;
						position: absolute;
						left: 0;
					}				 		
			 	}
			}
			.content{
				background: transparent;
				margin-bottom: 50px;
			}
		}

		.section-arrow{
			padding-bottom: 0;
		}

		.section-arrow-2{
			position: relative;
			background: #fff;
			padding-top: 0;
			padding-bottom: 0;
			margin: 0;
			.inside-container{
				&:before{
					background-image: url(../images/fleche-gris-mobile.png);
					background-repeat: no-repeat;
					background-position: center top;
					height: 28px;		
				    margin-top: -2px;
				    display: block;
				    content: "";
				}		
			}	
			&.grey{
				background: #f3f3f3;
				.inside-container{
					&:before{
						background-image: url(../images/fleche-blanc-mobile.png);
					}		
				}					
				&:first-child{
					.inside-container{
						&:before{
							content: none;
						}		
					}	
				}
			}					
		}	

		.container-table{
			display: none;
		}

	}
	&.page-template-template-galerie-php{
		.section-arrow{
			padding-bottom: 35px;
		}
		.all-galeries{
			.galerie{
				margin: 10px 0;
				h2{
					margin-top: 15px;
					margin-bottom: 0;
					font-size: rem-calc(14);
					line-height: rem-calc(16.8);
				}
				p{
					font-size: rem-calc(12);
					line-height: rem-calc(14.4);			
					color: $grey;
				}
			}
		}
	}
	&.page-template-template-presse-php{
		.main{
			section{
				padding-top: 0;
				padding-bottom: 0;
			}
			
			a:not(.refresh){
				color: $grey;
				.category{
					color: #828487;
					text-transform: uppercase;
					font-weight: 400;
					font-size: rem-calc(10);
				}
				.date{
					font-size: rem-calc(10);
					color: $blue;
				}		
			}
			h2{
				color: $grey;
				font-weight: 500;
				margin-top: 0;
				margin-bottom: 0;
				font-size: rem-calc(13);
			}

			.btn-2{
				background: #fff;
				a{
					text-decoration: none;
				}
				&:hover{
					a{
						text-decoration: underline;
					}
				}
			}

		}
	}
	&.page-template-template-histoire-php{
		.main{
			.content{
				h2{
					text-transform: uppercase;
					margin-top: 20px;
					margin-bottom: 20px;
				}
			}			
		}

		.cd-container {
			margin: 0 auto;
			&::after {
			  /* clearfix */
			  content: '';
			  display: table;
			  table-layout: fixed;
			  clear: both;
			}
		}

		#cd-timeline {
			position: relative;
			padding: 40px 0 0;
			margin-top: 0;
			margin-bottom: 0;
			padding-bottom: 30px;

		}

		.cd-timeline-block {
			position: relative;
			margin: 2em 0;
			&:after {
			  content: "";
			  display: table;
			  table-layout: fixed;
			  clear: both;
			}
			&:first-child {
			  margin-top: 0;
			}
			&:last-child {
			  margin-bottom: 0;
			}
		}

		.cd-timeline-content {
			position: relative;
			padding-top: 40px;
			&:after {
			  content: "";
			  display: table;
			  table-layout: fixed;
			  clear: both;
			}
			.cd-date {
				left: -15px;
				top: 0;
				position: absolute;
				padding: 0 30px;
				background: $blue;
				display: inline-block;
				color: #fff;
				line-height: 40px;
				height: 40px;
				font-size: rem-calc(20);
				font-weight: 500;
				&:after{
			  		@include top-right(40px, 20px);
				}
			}
			&::before {
			  content: '';
			  position: absolute;
			  top: 16px;
			  right: 100%;
			  height: 0;
			  width: 0;
			}
		}

	}
	&.page-template-template-chiffres-php{
		.all-chiffres{
			li{
				border-top: 0;
				padding-bottom: 0;
				&+li{
					.content-inside{
						border-top: 1px solid #fff;
					}
				}				
				.content-inside{
					padding-left: 84px;
					padding-top: 20px;
					padding-bottom: 20px;
					position: relative;
					div{
						width: 64px;
						height: 64px;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -32px;
						background-size: 64px 64px;
						background-repeat: no-repeat;
						background-position: center center;
					}
					p{
						display: inline-block;
						vertical-align: middle;
						color: $grey;	
						margin-bottom: 0;						
						font-size: rem-calc(14);
						line-height: rem-calc(16.8);
						span{
							// display: block;
							font-weight: 900;
							color: $blue;								
							font-size: rem-calc(32);
							line-height: rem-calc(38.4);
							&.petit{
								color: $grey;
								font-weight: 400;
								font-size: rem-calc(10);
								line-height: rem-calc(12);								
							}
						}
					}
				}
			}
		}
	}
	&.page-template-template-plan-site-php{
		main{
			.content{
				ul{
					margin-bottom: 30px;
					ul{
						margin-bottom: 0!important;
					}
				}
			}
		}
	}

}

@media #{$small-up} { 

}

@media screen and (max-width: 500px) {
	.main{
		section{
			&.reseau{
				background-size: 130%;
				p.title{
					margin-bottom: 300px;
				}				
			}
		}
	}
}

@media #{$small-only} { 

}

@media #{$small-only} and (orientation: landscape) { 
	.landscape-mobile{ 
    	@include block-grid(2);
  	}
}

@media #{$medium-up} { 

	.main{
		section{
			&.reseau{
				background-position: right 115px;		
				p.title{
					margin-bottom: 615px;
				}						
				.compteur{
					>div{
						p{
							line-height: rem-calc(20);
							font-size: rem-calc(20);
							span{
								font-size: rem-calc(14);	
							}
						}

					}	
					.number{
						margin-top: 10px;
						text-align: left;
						span{
							text-align: center;
							&.odometer-digit{
								width: 53px;
								height: 74px;
								margin: 0 2.5px;
								.odometer-value{
									font-size: rem-calc(50);
									line-height: 74px;
								}							
							}		
							&.odometer-formatting-mark{
								margin: 0 10px;
							}								
						}		
					}		
				}
			}									
		}
	}

	.main{
		section{
			// padding: 70px 0;	
			.inside-container{
				&:before{
					transform: none;
					left: 0;
				}
			}	
			&.section-blog{
				padding-top: 90px;
				&.side{
					padding: 0;
					.all-articles{
						margin: 0 -15px;
						>div{
							padding: 0 15px;
							article{
								margin-bottom: 40px;								
							}
						}			
					}	

				}		
				.inside-container{
					&:before{
						position: absolute;
						content: url(../images/fleche-sep-gris.png);
						top: 0;
						margin-top: -50px;
					}	
				}	
				.all-articles{
					// margin: 0 rem-calc(-32.5);
					>div{
						// padding: 0 rem-calc(32.5);
						article{
							border-top: none;
						}
						&+div{
							article{
								border-top: none;
							}
						}						
					}
					article{
						max-width: 336px;
						margin: auto;
						border-bottom: none;
						background: #fff;
						padding: 0;
						.descriptif{
							min-height: 230px;
							border-top: 1px solid #e6e6e6;
							padding: 25px 25px 90px;
							position: relative;		
							p{
								font-size: rem-calc(22);
								line-height: rem-calc(28);
							}									
							.date{
								font-size: rem-calc(13);
							}
						}
						.social-share{
							display: inline-block;
							position: absolute;
							bottom: 20px;
							left: 0;
							right: 0;
							text-align: center;
						}						
					}
				}
				a.btn{
					margin: 40px 0 0;
				}
			}	
			&.decouvrir-via{
				ul{
					li{
						text-align: center;
						div{
							display: inline-block;
							font-size: rem-calc(17);
							line-height: rem-calc(20);
							img, svg{
								display: block;
								margin: auto;
								width: 150px;
								height: 150px;
								margin-bottom: 5px;
							}
							span{
								display: block;
							}
						}
					}
				}
			}	
			&.instagram{
				padding-top: 160px;
				.inside-container{
					&:before{
						position: absolute;
						content: url(../images/fleche-sep-blanc.png);
						top: -72px;
					}	
				}
				.list{
					span{
						font-size: rem-calc(14);
						line-height: rem-calc(16.8);
					}
					p{
						font-size: rem-calc(14);
						line-height: rem-calc(16.8);
					}
				}
			}								
		}
	}	

	.page{
		&.page-template-template-blog{
			
		}
		&.page-template-template-faq{
			.accordion{
				.content{
					padding: 0 25px 45px;
					p{
						font-size: rem-calc(18);
						line-height: rem-calc(30);
					}
				}
				.btn-2{
					padding-right: 60px;
					padding-top: 25px;
					padding-bottom: 25px;
					font-size: rem-calc(16);
					img, svg{
						margin-top: -16px;
						width: 32px;
						height: 32px;
					}
				}				
			}
		}
		&.page-template-template-clients-php, &.page-template-template-chargeurs-php, &.page-template-template-terminaux-php{
			.accordion{
				 .accordion-navigation{
				 	margin: 30px 0; 
				 	display: table;
				 	table-layout: fixed;
				 	>a{
				 		display: table-cell;
				 		width: 50%;
				 		max-width: 540px;
				 		padding: 0;	
				 		position: relative;
				 		vertical-align: top;
				 		cursor: default;
				 		&:hover{
				 			text-decoration: none;
				 			h2{
				 				background: $blue;
				 			}
				 		}
				 		h2{
							padding: 0 20px;
							font-size: rem-calc(18);
							position: absolute;
							line-height: 56px;
							top: 20px;
							left: 0;
							min-width: 270px;
							&:after{
								@include top-right(56px, 35px);
							}							
							img, svg{
								&.plus{
									display: none;
								}
								&.moins{
									display: none;
								}
							}						
				 		}
				 	}
					&.active{
				 		>a{
				 			h2{
				 				background: $blue;
								img, svg{
									&.plus{
										display: none;
									}						
									&.moins{
										display: none;
									}						
								}			 				
				 			}
				 		}
				 	}

				 }

				 .content{
				 	padding: 0;
				 	display: table-cell;
				 	vertical-align: top;
				 	width: 50%;
				 	margin: 0;
				 	background: none;
				 	p{
				 		// padding:  0 20px 20px;
				 	}
					.btn{
						margin: 0 20px;
					}
					.padding-10{
						padding: 0 20px 20px;
					}
				 }				 
			}
		}
		&.page-template-template-clients-php, &.page-template-template-chargeurs-php{
			.accordion{

			}
		}
		&.page-template-template-terminaux-php{
			.all-terminaux{
				padding-top: 0;
				padding-bottom: 0;
				background: none;
			}
			.section-arrow-2{
				position: relative;
				background: #fff;
				padding-top: 70px;
				padding-bottom: 70px;
				margin: 0;
				.inside-container{
					&:before{
						content: url(../images/fleche-sep-gris.png);
					}		
				}	
				&.grey{
					background: #f3f3f3;
					.inside-container{
						&:before{
							content: url(../images/fleche-sep-blanc.png);
						}		
					}					
					&:first-child{
						.inside-container{
							&:before{
								content: none;
							}		
						}	
					}
				}					
			}	
			.informations{
				margin: 20px 10px;
				>li{
					padding-bottom: 20px;
					border-top: 1px solid #cacacb;
					border-bottom: 0;
					>p{
						&.maj-gris{
							text-transform: uppercase;
							margin-bottom: 5px;
						}
						margin-bottom: 0;
						font-size: rem-calc(16);
						line-height: rem-calc(19);
					}					
					&+li{
						border-bottom: 0;
					}
				}
				.equipements{
					padding: 0;
				}
			}
			.accordion{
				 .content{
					.btn{
						float: right;
					}	
				 }
			}						
		}	
		&.page-template-template-plus-viia-php{
			.accordion{
				.accordion-navigation{
			 		margin: 30px 0 100px!important; 
				 	>a{
				 		display: block;		 		
				 		h2{			 			
				 			color: $blue;	
				 			background: transparent;	
							padding-left: 0;
							padding-right: 0;	
							position: relative;
							font-weight: 900;
							font-size: rem-calc(26);
							line-height: rem-calc(31.2);
							margin-bottom: 30px;
							svg{
								right: 0;
							}
							&:after{
								border: none;
							}
				 		}
				 		img{
				 			position: relative;
				 			width: 80px;
				 			height: 80px;
				 			margin-top: 0;
				 			top: 0;
				 			margin-right: 20px;
				 			float: left;
				 			
				 		}
				 	}
				}
			}

			.section-arrow-2{
				position: relative;
				background: #fff;
				padding-top: 0;
				padding-bottom: 0;
				margin: 0;
				.inside-container{
					&:before{
						background-image: url(../images/fleche-sep-gris.png);
						background-position: left top;
						height: 34px;		
					}		
				}	
				&.grey{
					background: #f3f3f3;
					.inside-container{
						&:before{
							background-image: url(../images/fleche-sep-blanc.png);
						}		
					}					
					&:first-child{
						.inside-container{
							&:before{
								content: none;
							}		
						}	
					}
				}					
			}	
		}
		&.page-template-template-galerie-php{

			.section-arrow{
				.inside-container{
					&:before{
						background-position: center top;
					}
				}
			}	

			.all-galeries{
				.galerie{
					margin: 10px 0 50px;
					h2{
						margin-top: 0;
						margin-bottom: 5px;
						font-size: rem-calc(16);
						line-height: rem-calc(19.2);
					}
					p{
						font-size: rem-calc(14);
						line-height: rem-calc(16.8);			
					}
				}
			}
		}
		&.page-template-template-histoire-php{
			.section-arrow{
				padding-bottom: 0;
				.inside-container{
					&:before{
						background-position: center top;
					}
				}
			}	
			.main{
				.content{
					h2{
						margin-top: 30px;
						margin-bottom: 20px;
					}
				}			
			}	

			  #cd-timeline {
				&::before {
					/* this is the vertical line */
					content: '';
					position: absolute;
					top: 0;
					left: 18px;
					height: 100%;
					width: 4px;
					background: $blue;		
				    left: 50%;
				    margin-left: -2px;
				  }    
			  }

			  .cd-timeline-block {
				margin: 0;   
			  }

			  .cd-timeline-content {
			    margin-left: 0;
			    padding: 56px 40px 40px;
			    width: 50%;
				&::before {
					top: 24px;
					left: 100%;
					border-color: transparent;
					border-left-color: white;
				}
				.cd-date {
					position: absolute;
					right: 0;
					left: auto;
					top: 0;
					height: 56px;
					line-height: 56px;
					padding-right: 40px;
					font-size: rem-calc(28);
					&:after{
				  		@include top-right(56px, 20px);
						border-width: 0 20px 56px 0;
						border-color: transparent $blue transparent transparent;
						left: -20px;
						right: auto;
					}		
				}
				p{
					text-align: right;
					
				}
			  }

			  .cd-timeline-block:nth-child(odd) .cd-timeline-content {
			    float: right;
				&::before {
					top: 24px;
					left: auto;
					right: 100%;
					border-color: transparent;
					border-right-color: white;
				}
				.cd-date {
					right: auto;
					left: 0;
					text-align: right;
					padding-left: 40px;		
					&:after{
				  		@include top-right(56px, 20px);
						left: auto;
						right: -20px;	  		
					}
				}   
				p{
					text-align: left;
				} 
			  }

		}
		&.page-template-template-presse-php{
			.main{
				
				a:not(.refresh){
					.category{
						font-size: rem-calc(13);
					}
					.date{
						font-size: rem-calc(13);
					}		
				}
				h2{
					font-size: rem-calc(16);
					line-height: rem-calc(28);
				}

			}
		}	
		&.page-template-template-chiffres-php{
			.all-chiffres{
				li{
					border-top: 0;
					&+li{
						border-top: 1px solid #fff;
						.content-inside{
							border-top: 0;
						}
					}
					&:nth-child(odd){
						.content-inside{
							padding-left: 0;
							padding-right: 164px;
							position: relative;
							text-align: right;
							&:before{
								content: none;
							}
							div{
								left: auto;
								right: 40px;
							}
							p{
								text-align: right;
							}
						}					
					}
					&:nth-child(2){
						border-top: 0;						
					}
					.content-inside{
						border-top: 0;
						padding-left: 164px;
						padding-top: 32px;
						padding-bottom: 32px;
						position: relative;
						&:before{
							border-left: 1px solid #fff;
							content: "";
							height: 104px;
							position: absolute;
							left: -10px;
							top: 50%;
							margin-top: -52px;
						}
						div{
							width: 104px;
							height: 104px;
							left: 40px;
							margin-top: -52px;
							background-size: contain;
						}
					}					
				}
			}
		}			
	}	

}
@media #{$medium-only} { 

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	.landscape-mobile{ 
    	@include block-grid(2);
  	}
}

@media #{$large-up} { 


	.main{
		section{
			&.section-blog{
				.all-articles{
					margin: 0 rem-calc(-32.5);
					>div{
						padding: 0 rem-calc(32.5);					
					}
				}
			}	
			&.reseau{
				position: relative;
				padding-bottom: 0;
				padding-top: 70px;
				background-position: right top;

				.inside-container{
					&:before{
						position: absolute;
						content: url(../images/fleche-sep-blanc.png);
						top: -72px;
					}	
				}
				p.title{
					margin-bottom: 25px;
				}

				.btn{
					margin-bottom: 200px;
				}


				.legende{
					position: absolute;
					display: block;
					bottom: 150px;
					left: 0;
					margin: 0;
					padding: 0;
					list-style: none;
					li{
						font-size: rem-calc(11);
						color: #717275;
						margin-left: 0;
						position: relative;
						&+li{
							margin-left: 15px;
						}
						svg, img{
							display: inline-block;
							vertical-align: middle;
							margin-right: 2px;						
						}
						&.ligne{
							padding-left: 15px;							
							span{
								background: #000;
								width: 10px;
								height: 3px;
								position: absolute;
								left: 0;
								top: 50%;
								margin-top: -1.5px;
								border-radius: 2px;
							}
						}
						&.ligne-p{
							padding-left: 15px;							
							span{
								position: absolute;
								left: 0;
							}
						}						
						&.point{
							padding-left: 15px;							
							span{
								position: absolute;
								left: 0;
								top: 50%;
								width: 10px;
								height: 10px;
								background: #000;
								margin-top: -5px;
								border-radius: 50%;
							}							
						}
					}
				}

				.compteur{
					position: absolute;
					left: 0;
					bottom: -40px;					
					height: 180px;
					&:before{
						@include top-right(180px, 60px);
					}
					svg, img{
						width: 108px;
						height: 108px;
					}
					>div{
						padding: 25px 0;
						width: 720px;
						p{
							line-height: rem-calc(20);
							font-size: rem-calc(22);
							span{
								font-size: rem-calc(16);	
								display: inline;
							}
						}

						&:after{
							content: none;
						}						

					}	
					.number{
						clear: none;
						// text-align: left;
						span{
							&.odometer-digit{
								.odometer-value{
									font-size: rem-calc(70);
								}
							}
						}		
					}		
				}
			}	
		}
	}	

	.page{
		&.page-template-template-plus-viia-php{
			.container-table{
				display: block;
				margin-left: -38px;
				margin-right: -38px;
				margin-top: 40px;
				margin-bottom: 40px;
				.table-pictos{
					display: table;
					table-layout: fixed;
					width: 100%;
					margin: 0;
					li{
						display: table-cell;
						width: 20%;
						float: none;
						position: relative;
						&:before{
							content: none;
						}
						div{
							padding: 0 38px;
							margin-left: auto;
							margin-right: auto;
						}
				 		img{
				 			width: 80px;
				 			height: 80px;
				 			margin-bottom: 10px;
				 			display: block;
							margin-left: auto;
							margin-right: auto;
				 		}	
				 		p{
							text-align: center;
							font-size: rem-calc(20);
							line-height: rem-calc(24);
							color: $blue;
							font-weight: 700;		
							margin-bottom: 0;		 			
				 		}
				 		&+li{
				 			&:before{
				 				content: url(../images/picto-sep-plus-viia.svg);
				 				position: absolute;
				 				left: -20px;
				 				top: 50%;
				 				width: 40px;
				 				height: 40px;
				 			}
				 		}				
					}
				}
			}
		}
		&.page-template-template-chiffres-php{
			.all-chiffres{
				li{
					.content-inside{
						p{
							font-size: rem-calc(24);
							line-height: rem-calc(28.8);
							span{						
								font-size: rem-calc(56);
								line-height: rem-calc(67);
								&.petit{
									font-size: rem-calc(16);
									line-height: rem-calc(19.2);								
								}													
							}
						}
					}					
				}
			}
		}	

		
	}

}

@media #{$large-only} { 

}



@media only screen and (min-width: 1024px) {

  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }

  /* inverse bounce effect on odd content blocks */
  .cssanimations .cd-timeline-block:nth-child(odd) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }

}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
