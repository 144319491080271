body{
	margin-top: 45px;
}

.submenu-list{
	margin-bottom: 0;
	margin-left: 0;	
	display: inline-block;
	line-height: 30px;
	>li{
		text-align: right;
		display: inline-block;
		float: left;	
		&+li{
			a{
				position: relative;
				&:before{
					border-left: 1px solid #a6a7a9;
					position: absolute;
					top: 50%;
					left: 0;
					content: "";
					width: 1px;
					height: 10px;
					margin-top: -5px;
				}
			}
		}
		a{
			border-left: none;
			padding-left: 10px;
			padding-right: 10px;
			font-size: rem-calc(13);
			color: $grey;
			font-weight: 500;	
			display: block;		
		}
		&:hover, &:focus, &:visited{
			ul{
				li{
					a{
						color: #fff;
						display: block;
					}
				}
			}
		}
	}
}

nav.custom{
	line-height: rem-calc(30);

	li{
		&.has-dropdown{
			> a{
				cursor: default;
			}
		}
		a{
			svg{
				vertical-align: middle;
			}
		}
		&:hover, &:focus, &:visited{
			background: $grey;
			a{
				color: #fff;
				svg{
					// fill
					.hover-change{
						fill: #fff;
					}
				}
			}
		}
	}

	.languages{
		>li{
			>a{
				position: relative;
				&:before{
					border-left: 1px solid #a6a7a9;
					position: absolute;
					top: 50%;
					left: 0;
					content: "";
					width: 1px;
					height: 10px;
					margin-top: -5px;
				}	
				&:after{
					border-left: 1px solid #a6a7a9;
					position: absolute;
					top: 50%;
					right: 0;
					content: "";
					width: 1px;
					height: 10px;
					margin-top: -5px;
				}								
			}
			a{
				img{
					margin-top: -2px;
					margin-right: 3px;
				}
				svg{
					margin-left: 10px;
				}
			}				
			.dropdown{
				padding: 0;
			}
		}
	}

	.has-dropdown{
		position: relative;
		text-align: left;
		.dropdown{		
			list-style-type: none;
			margin-left: 0;
		    overflow: hidden;
		    display: none;
		    position: absolute!important;
		    width: 1px;
		    padding: 0;
		    top: 0;
			z-index: 99;
			min-width: 100%;
    		top: auto;			
    		left: 0;
    		background: $grey;
    		color: #fff;
    		padding: 15px 0;
			a{
				white-space: nowrap;
				padding: 7.5px 15px;
			}

		}
		&:hover, &:focus, &:visited{
			.dropdown{
			    width: auto;
			    overflow: visible;
			    clip: auto;
			    display: block;		
			    li{
			    	&:hover{
				    	a{
				    		background: #fff;
				    		color: $grey;
				    	}
			    	}
			    }
			}
		}
	}
}

.header-slick{
	background: #f3f3f3;
	position: relative;
    display: none;
    &.slick-initialized {
        display: block;
    }	
	&.slick-slider.slick-dotted{
		margin-bottom: 0;
		.slick-dots{
			bottom: 48px;
			left: 50%;
			transform: translateX(-50%); 
			width: inherit;
			li{
				&.slick-active{
					button{
						&:before{
							font-size: 35px;
							opacity: 1;
							color: #fff;
						}
					}				
				}
				button{
					&:before{
						content: "■";
						color: #ffffff;
						opacity: 0.5;
						font-size: 30px;
					}
				}
			}
		}

	}
	figure{
		position: relative;
		margin: 0;
		img{
			width: 100%;
			height: auto;
		}
		figcaption{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 25px;
			z-index:10;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			font-size: rem-calc(19);
			line-height: rem-calc(21);
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
		}
	}
	.slick-slide{
		position: relative;
		a{
			color: #fff;
		}
		.compteur{
			text-align: right;
			position: relative;
			color: #fff;
			background-color: $blue;	
			z-index: 15;
			padding-bottom: 24px;
			margin-bottom: 24px;
			&:after{
				@include bottom-arrow-after();
			}			
			>div{
				padding: 15px 15px 35px;
				p{
					line-height: rem-calc(18);
					a{
						font-size: rem-calc(14);
						line-height: rem-calc(18);
						margin-bottom: 0;
						span{
							font-size: rem-calc(18);
							line-height: rem-calc(22);
							display: block;
						}
						&:hover{
							text-decoration: none;
						}
					}
				}
				svg{
				    display: none;			
				}
			}
		}
	}

	.slick-prev, .slick-next{
		top: inherit;
		bottom: 15px;
		z-index: 5;
		&:before{
			content: none;
		}
	}
	.slick-prev{
		left: 15px;
	}
	.slick-next{
		right: 15px;
	}

}

.header{
	background: none;
    position: fixed;
    top: 0;
    left: 0;
	width: 100%;
    display: block;
    z-index: 30;
	.logo-mobile{
		max-width: 128px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.top-bar-header{
		background: #f2f2f2;
		text-align: right;
		height: 30px;
	}
	.top-bar{
		background: $blue;
		max-width: none;
		&.expanded{
			.top-bar-section:not(.in-dropdown){
				overflow-y: auto;
    			max-height: 100vh;		
			    margin-top: -45px;
			    padding-top: 45px;

			}	
			.toggle-topbar.menu-icon{
				a{
					&:hover, &:focus{
						background: none;
					}
					span{
						&:after{
							content: url(../images/close-menu.svg);
							width: 17px;
							height: 17px;
							border: 0;
							margin-top: -15px;
							box-shadow: none;										
						}
					}
				}
			}
		}
		.top-bar-section{	
			background: #2b5899;				
			>ul{
				padding-top: 30px;		
				>li{
					&.has-dropdown{
						>a{
							position: relative;
							&:after{
								position: relative;
								content: url(../images/mobile-next.svg);
								float: right;
								width: 21px;
								margin-right: 0;
								height: 16px;
								border: 0;
								margin-top: 0;
								top: 0;
							}
						}
						>ul{
							padding-top: 30px;
							li{
								a{
									text-transform: none;	
									font-weight: 400;
									&:before{
										content: none; 
									}														
								}
								&+li{
									a{
										&:before{
											content: "/"; 
											margin-right: 10px;
											display: inline-block;
										}														
									}									
								}
								&.has-dropdown2{
									a{
										position: relative;
										&:after{
											content: none;
											border: none;
											margin: 0;
											padding: 0;
										}
									}
									.sub-dropdown{
										border-top: 1px solid #fff;
										li{
											border: none;
											padding-left: 10px;
											a{
												font-size: rem-calc(12);
												&:before{
													content: "›"; 
													margin-right: 10px;
													display: inline-block;
												}
											}
										}
									}
								}	
							}			
						}						
					}
				}				
			}

			ul{
				display: inline-block;
				background: #2b5899;					
				&.top-social{
					padding-top: 0;
				}
				&.top-footer{
					padding-top: 10px;
					padding-bottom: 10px;
					li{
						border-bottom: none;
						a{
							font-weight: 400;
							text-transform: none;
							
						}
					}
				}
				li{
					background: none;
					color: #fff;
					display: block;
					border-bottom: 1px solid #fff;
					a{
						line-height: 40px;
						background: transparent;
						text-transform: uppercase;
						font-weight: 500;
						padding: 0 25px;
						&:hover, &.active{
							background: #2b5899;
							line-height: 40px;						
						}
					}
					&.title.back.js-generated{
						h5{	

							a{
								position: relative;
								font-size: rem-calc(13);
								line-height: 40px;
								background: transparent;
								text-transform: uppercase;
								font-weight: 500;	
								// &:before{
								// 	position: relative;
								// 	content: url(../images/mobile-prev-01.svg);
								// 	float: left;
								// 	width: 21px;
								// 	margin-right: 0;
								// 	height: 16px;
								// 	border: 0;
								// 	margin-top: 3px;
								// 	top: 0;									
								// }						
								&:after{
									content: url(../images/close-menu.svg);
									float: right;
									width: 17px;
									height: 17px;
									border: 0;
									margin-top: 0;
									top: 0;							
								}
							}		
						}						
					}
				}
			}
			.search{
				float: right;
				background: $grey;
				height: 60px;
				width: 60px;
				padding: 0;
				top: 0;
				right: 0;
				border-left: 1px solid #999999;
			}

			.languages{
				background: #f3f3f3;
				text-align: center;
				padding: 5px 0;
				li{
					display: block;
					float: none;
					text-align: center;
					a{
						color: $grey;
						font-weight: 500;
						font-size: rem-calc(13);
						text-transform: none;
						&:hover, &:focus, &:visited{
							background: transparent;
						}
						img{
							margin-top: -2px;
							margin-right: 3px;
						}		
					}	
					ul{
						display: none;
						background: #f3f3f3;
						text-align: center;
						padding: 5px 0;						
						li{
							a{

							}
						}
					}
				}
			}

		}
		// mobile menu
		.title-area{
			z-index: 20;
			min-height: 45px;
			background: $blue;
			.name{
				a{
					display: block;
				}
			}
			.search-mobile{
				position: absolute;
				right: 60px;
				top: 0;
				background: none;
				padding: 0;
				line-height: 40px;
				// width: 18px;
				height: 40px;
				&:hover, &:focus{
					background: none;
				}
			}
			.search-form-mobile{
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.logo-mobile{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99999;
	}	

    .search-form {
        display: none;
        position: absolute;
        background: $blue;
        width: 100%;
        top: 0;
        right: -100%;
        height: 60px;
        color: #fff;
        // text-align: right;
        padding-left: 295px;
        input {
            display: inline-block;
            background: $blue;
            font-weight: 500;
            top: 0;
            height: 60px;
            border: none;
            color: #fff;
            padding: 0 10px;
        }
		::-webkit-input-placeholder{
			color: #fff;
			background: $blue;
			height: 60px;
			line-height: 60px;
			font-weight: 500;			
		}
		:-moz-placeholder{
			color: #fff;
			background: $blue;
			height: 60px;
			line-height: 60px;
			font-weight: 500;			
		}
		::-moz-placeholder{
			color: #fff;
			background: $blue;
			height: 60px;
			line-height: 60px;
			font-weight: 500;			
		}
		:-ms-input-placeholder{ 
			color: #fff;
			background: $blue;
			height: 60px;
			line-height: 60px;
			font-weight: 500;
		} 

        button {
        	&.search{
        		float: left!important;
        		border-left: none!important;
        	}        	
        	// &.close{
        	// 	float: left!important;
        	// 	border-left: none!important;
        	// }     
        }
		.icon-close{
            // &:after {
                // content: url(../images/close-search.svg);
                width: 26px;
                height: 26px;
                display: block;    
                position: absolute;  
                right: 0;
                z-index: 10;
				background: $grey;
				height: 60px;
				width: 60px;
				padding: 0;
				top: 0;
				right: 0;                
            // }  
        } 

		.table{
			display: table;
			width: 100%;
			>div{
				display: table-cell;
			}
			.button-30{
				width: 32px;
				vertical-align: top;
			}    
		}      
    }

	.search-form-mobile{
		position: fixed;
		left: 0;
		background: #2b5899;
	    right: 0;
	    top: 45px;
	    bottom: 0;
	    z-index: 9;
	    height: auto;
	    display: none;
	    label{
	    	margin: 30px 0 20px;
	    	color: #fff;
	    	padding: 10px 20px;
	    	text-transform: uppercase;
	    	font-weight: 500;
	    	font-size: rem-calc(13);
	    	border-bottom: 1px solid #fff;
	    }
	    button{
	    	background: transparent;
	    	padding: 0;
	    	&#close-search-btn-mobile{
	    		float: right;
	    	}
		}
		.table{
			display: table;
			width: 100%;
			padding: 0 20px;
			>div{
				display: table-cell;
				width: 100%;	
				input{
					line-height: 40px;
					font-size: rem-calc(12);
					color: $grey;
					font-weight: 500;
					height: 40px;
					border: 0;
					box-shadow: none;
					-webkit-border-top-left-radius: 4px;
					-webkit-border-bottom-left-radius: 4px;
					-moz-border-radius-topleft: 4px;
					-moz-border-radius-bottomleft: 4px;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;					
				}

				::-webkit-input-placeholder{
					color: $grey;
					background: #fff;
					font-size: rem-calc(12);
					font-weight: 500;					
				}
				:-moz-placeholder{
					color: $grey;
					background: #fff;
					font-size: rem-calc(12);
					font-weight: 500;					
				}
				::-moz-placeholder{
					color: $grey;
					background: #fff;
					font-size: rem-calc(12);
					font-weight: 500;					
				}
				:-ms-input-placeholder{ 
					color: $grey;
					background: #fff;
					font-size: rem-calc(12);
					font-weight: 500;
				} 

				&.search-div-button{
					width: 30px;
					button{
						background: #fff;
						border: 0;
						line-height: 40px;
						-webkit-border-top-right-radius: 4px;
						-webkit-border-bottom-right-radius: 4px;
						-moz-border-radius-topright: 4px;
						-moz-border-radius-bottomright: 4px;
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
						padding-left: 10px;						
						padding-right: 10px;						
					}		
				}
				&.top{
					vertical-align: top;	
				}					
				&.middle{
					vertical-align: middle;	
				}
			}
		}
		&.open{
			display: block;
		}
	}

}

.image{
	img{
		width: 100%;
	}
}

@media #{$small-up} { 

}
@media #{$small-only} { 

}

@media #{$medium-up} { 



}
@media #{$medium-only} { 

}

@media #{$large-up} { 

	body{
		margin-top: 90px;
	}

	.header{
		.top-bar{
			// Menu Principal
			.top-bar-section{	
				background: $blue;	
				height: 60px;	
	
				ul{
					display: inline-block;	
					background: $blue;	
					&.dropdown{
						padding-top: 10px;
						padding-bottom: 10px;
					}				
					li{
						background: transparent;
						color: #fff;
						display: block;
						border-bottom: none;
						a{
							line-height: 40px;
							background: transparent;
							text-transform: uppercase;
							padding: 0 25px;
						}						
						&:hover, &:focus, &.active{
							a{
								line-height: 40px;
								background: #2b5899;
							}
							&:hover, &:focus, &:visited{
								a{
									background: #2b5899;
								}								
							} 
						}
						&.title.back.js-generated{
							display: none;					
						}
					}
				}

				>ul{
					padding-top: 0;		
					line-height: 60px;					
					>li{
						line-height: 60px;
						>a{
							line-height: 60px;		
							padding: 0 10px!important;				
						}
						&:hover, &:focus, &.active{
							>a{
								line-height: 60px;
								background: #2b5899;
							}
						}						
						&.has-dropdown{
							padding-top: 0;
							// cursor: none;						
							>a{								
								&:after{
									content: none;
								}
								cursor: default;
							}
							&:hover, &.active{	
								// cursor: none;						
								ul{
									// height: auto!important;
									// max-height: none;
								}
							}
							ul.dropdown{
								// max-height: 0;
								// transition: height 1s ease;
								background: #2b5899;
								padding-top: 10px;
								li{
									background: #2b5899;
									&:hover, &:focus, &:visited{
										>a{
											background: $blue;
										}
									}
									a{
										font-size: rem-calc(16);
										background: #2b5899;
										&:before{
											content: none; 
										}														
									}
									&+li{
										a{
											&:before{
												content: "/"; 
												margin-right: 10px;
												display: inline-block;
											}														
										}									
									}
									&.has-dropdown2{
										a{
											position: relative;
											&:after{
												content: none;
												border: none;
												margin: 0;
												padding: 0;
											}
										}
										.sub-dropdown{
											padding-top: 0;
											border-top: none;
											li{
												padding-left: 0;
												a{
													line-height: 30px;
													color: #b8b9ba;
													font-size: rem-calc(14);
													&:before{
														content: "›"; 
														margin-right: 10px;
														display: inline-block;
													}
												}
											}
										}
									}	
								}			
							}						
						}
						// li{
						// 	background: transparent!important;
						// 	a{
						// 		// background: transparent!important;
						// 	}								
						// }
					}				
				}


			}
		}
	}

	.header{
		.top-bar{
			height: 60px;
		}	

		.search-form-mobile{
			display: none;
			&.open{
				display: none;
			}
		}

	}

	.title-area{
		img{
			margin-top: -30px;
		}
	}
}
@media #{$large-only} { 

}

@media only screen and (min-width: 1024px) {

	.header-slick{
		&.slick-slider.slick-dotted{
			margin-bottom: 0;
			.slick-dots{
				bottom: 55px;
				left: 80px; 
			}

		}
		figure{
			position: relative;
			margin: 0;
			img{
				width: 100%;
				height: auto;
			}
			figcaption{
				position: absolute;
				transform: none;
				top: 90px;
				left: 80px;
				color: #fff;
				z-index:10;
				text-transform: uppercase;
				font-weight: 700;
				font-size: rem-calc(40);
				line-height: rem-calc(42);
				text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
			}
		}
		.slick-prev, .slick-next{
			display: none!important;
		}
		.slick-slide{
			position: relative;
			padding-bottom: 30px;
			.compteur{
				text-align: right;
				position: absolute;
				left: 40%;
				right: 0;
				bottom: 0;
				color: #fff;
				background-color: $blue;	
				min-height: 160px;
				z-index: 15;
				padding-bottom: 0;
				margin-bottom: 0;
				&:before{
					// @include bottom-left(160px, 60px);
					position: absolute;
					left: -114px;
					top: 0;
					bottom: 0;
					overflow: hidden;
					content: url(../images/slider-home-triangle.png);
				}
				&:after{
					content: none;
				}					
				>div{
					padding: 10px 90px 10px 55px;
					height: inherit!important;
					p{
						line-height: rem-calc(20);
						a{
							font-size: rem-calc(16);						
							line-height: rem-calc(20);
							span{
								font-size: rem-calc(23);
								line-height: rem-calc(25);
							}
						}
					}
					svg{
					    position: absolute;
					    right: 0;
					    top: 50%;
					    margin-top: -25px;		
					    display: inline-block;		
					}					
				}
			}
		}

	}

}

@media #{$xlarge-up} { 

	.header{
		.top-bar{
			// Menu Principal
			.top-bar-section{	
				>ul{
					>li{
						>a{
							padding: 0 30px!important;				
						}
					}
				}
			}


			// mobile menu
			.title-area{
				.search-form-mobile{
					display: none;
				}
			}

		}
	}

}
@media #{$xlarge-only} { 

}

@media #{$xxlarge-up} { 


	.header-slick{
		.slick-slide{
			.compteur{				
				>div{
					p{
						line-height: rem-calc(20);
						a{
							line-height: rem-calc(20);
							span{
								font-size: rem-calc(30);
								line-height: rem-calc(35);
							}
						}
					}				
				}
			}
		}

	}

}
@media #{$xxlarge-only} { 

}