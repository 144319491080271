.ninja-forms-cont{
	select, input[type='text'], input[type="email"], textarea{
		border: none;
		box-shadow: none;
		background: none;
		color: $grey;
		font-weight: 500;	
		font-size: rem-calc(15);
		line-height: rem-calc(18);
		// padding: 25px;	
		margin-bottom: 0;
	}
	.label-above, .text-wrap, .textarea-wrap{
		label{
			display: none!important;
		}
	}
	::-webkit-input-placeholder{
		color: $blue;
		font-weight: 500;	
		font-size: rem-calc(15);
		line-height: rem-calc(18);
	}	
	:-moz-placeholder{
		color: $blue;
		font-weight: 500;	
		font-size: rem-calc(15);
		line-height: rem-calc(18);
	}	
	::-moz-placeholder{
		color: $blue;
		font-weight: 500;	
		font-size: rem-calc(15);
		line-height: rem-calc(18);
	}	
	:-ms-input-placeholder{
		color: $blue;
		font-weight: 500;	
		font-size: rem-calc(15);
		line-height: rem-calc(18);
	}
	#nf_submit_1, #nf_processing_1, #nf_submit_5, #nf_processing_5, #nf_submit_8, #nf_processing_8{
		position: relative;
		&:before{
			content: url(../images/picto-a-mobile.svg);
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 40px;
			background: $grey;
			z-index: 9;
			border-radius: 4px;
			padding: 7px 9px;
		}
		&:after{
			@include top-right(40px, 10px);
			border-color: $grey transparent transparent transparent;
			position: absolute;
			left: 40px;
			z-index: 9;
		}					
		.btn{
			border: 0;
			box-shadow: none;
			margin-top: 0;
			margin-bottom: 0;
			border-radius: 4px;
			// width: 100%;
		}
	}
	.field-wrap:not(.submit-wrap):not(.checkbox-wrap){
		background: #f2f2f2;
		display: block;
		padding: 15px 15px;
		font-size: rem-calc(15);
		font-weight: 500;
		border-left: 4px solid $blue;
		margin-top: 10px;
		margin-bottom: 10px;
		color: $blue;
		position: relative;
		&.list-dropdown-wrap, &.country-wrap{
			padding: 15px 0;
			.ninja-forms-field-error{
				padding: 0 15px;
			}
		}		
	}
	.checkbox-wrap{
		label{
			font-weight: 400;
			font-size: rem-calc(13);
			line-height: rem-calc(15);	
			margin-left: 0;		
			vertical-align: middle;
		}
	}

	// .ninja-forms-required-items{
	// 	display: none;
	// }

	.required-wrap{
		position: relative;
		.custom-dropdown-area{
			a{
				>div{
					display: inline-block;
					&:after{
						position: absolute;
						right: 55px;
						content: "*";
						color: #ff0000;
						display: inline-block;
						margin-left: 5px;
					}
				}
			}
		}
		&.text-wrap, &.textarea-wrap{
			position: relative;
			&:after{
				position: absolute;
				right: 15px;
				content: "*";
				color: #ff0000;
				display: inline-block;
				margin-left: 5px;
				top: 20px;
			}
		}
	}

	.custom-dropdown-area{
		width: 100%;
		.custom-dropdown-button{
			border: none;
			box-shadow: none;
			background: none;
			font-size: rem-calc(15);	
			line-height: rem-calc(18);
			color: inherit;
			padding-left: 23px;
			padding-right: 23px;
			text-decoration: none;
			position: relative;
			&:hover{
				text-transform: none;
				color: $blue;
			}
			&:after{
				content: url(../images/burger-menu-bleu.svg);
				width: 24px;
				height: 18px;
				right: 15px;
				top: 8px;
				position: absolute;
			}
			.selected{
				color: $grey;
			}
			.undefined{
				color: $blue;
			}			
		}
		ul{
			padding-left: 15px;
			padding-right: 15px;	
			border-color: $blue;
			background: #f3f3f3;	
    		max-height: 200px;
    		overflow: auto;				
			li{
				padding-left: 0;
				padding-right: 0;
				span{
					font-size: rem-calc(15);
					line-height: rem-calc(18);
				}		
				&.selected{
					display: none;
				}
			}
		}		
	}


	/* Cachons la case à cocher */
	[type="checkbox"]:not(:checked),
	[type="checkbox"]:checked {
	  position: absolute;
	  left: -9999px;
	}
	 
	/* on prépare le label */
	[type="checkbox"]:not(:checked) + label,
	[type="checkbox"]:checked + label {
	  position: relative; /* permet de positionner les pseudo-éléments */
	  padding-left: 25px; /* fait un peu d'espace pour notre case à venir */
	  cursor: pointer;    /* affiche un curseur adapté */
	}

	/* Aspect des checkboxes */
	/* :before sert à créer la case à cocher */
	[type="checkbox"]:not(:checked) + label:before,
	[type="checkbox"]:checked + label:before {
	  content: '';
	  position: absolute;
	  left:0; top: 2px;
	  width: 17px; height: 17px; /* dim. de la case */
	  border: 1px solid $blue;
	  background: #f8f8f8;
	  border-radius: 3px; /* angles arrondis */
	  box-shadow: inset 0 1px 3px rgba(0,0,0,.3); /* légère ombre interne */
	}
	 
	/* Aspect général de la coche */
	[type="checkbox"]:not(:checked) + label:after,
	[type="checkbox"]:checked + label:after {
	  content: '';
	  width: 11px;
	  height: 11px;
	  border-radius: 3px;
	  background: $grey;
	  position: absolute;
      top: 5px;
      left: 3px;
	  font-size: 14px;
	  color: $blue;
	  transition: all .2s; /* on prévoit une animation */
	}
	// Aspect si "pas cochée"
	[type="checkbox"]:not(:checked) + label:after {
	  opacity: 0; 
	  transform: scale(0);
	}
	/* Aspect si "cochée" */
	[type="checkbox"]:checked + label:after {
	  opacity: 1; 
	  transform: scale(1); /* mise à l'échelle 1:1 */
	}

	/* aspect désactivée */
	[type="checkbox"]:disabled:not(:checked) + label:before,
	[type="checkbox"]:disabled:checked + label:before {
	  box-shadow: none;
	  border-color: #bbb;
	  background-color: #ddd;
	}
	/* styles de la coche (si cochée/désactivée) */
	[type="checkbox"]:disabled:checked + label:after {
	  color: #999;
	}
	/* on style aussi le label quand désactivé */
	[type="checkbox"]:disabled + label {
	  color: #aaa;
	}
	 
	/* aspect au focus de l'élément */
	[type="checkbox"]:checked:focus + label:before,
	[type="checkbox"]:not(:checked):focus + label:before {
	  border: 1px dotted blue;
	}	

}

#subscribe-result{
	.error{
		display: none;
	}
}

@media #{$small-only} { 
	.ninja-forms-cont{
		#nf_submit_1, #nf_processing_1, #nf_submit_5, #nf_processing_5, #nf_submit_8, #nf_processing_8{					
			.btn{
				width: 100%;
			}
		}
	}
}

@media #{$medium-up} { 

	.ninja-forms-cont{
		select, input[type='text'], input[type="email"], textarea{
			font-size: rem-calc(18);
			line-height: rem-calc(21.6);
		}
		::-webkit-input-placeholder{
			font-size: rem-calc(18)	;
			line-height: rem-calc(21.6);
		}	
		:-moz-placeholder{
			font-size: rem-calc(18)	;
			line-height: rem-calc(21.6);
		}	
		::-moz-placeholder{
			font-size: rem-calc(18)	;
			line-height: rem-calc(21.6);
		}	
		:-ms-input-placeholder{
			font-size: rem-calc(18)	;
			line-height: rem-calc(21.6);
		}

		#nf_submit_1, #nf_processing_1, #nf_submit_5, #nf_processing_5, #nf_submit_8, #nf_processing_8{
			position: relative;
			&:before{
				content: url(../images/picto-a.svg);
				width: 50px;
				padding: 15px 9px;
			}
			&:after{
				@include top-right(60px, 20px);
				border-color: $grey transparent transparent transparent;
				left: 50px;
			}		
			.btn{
				padding-right: 50px;
				padding-left: 110px;
			}			
		}
		.checkbox-wrap{
			label{
				font-size: rem-calc(18)	;
				line-height: rem-calc(30);			
			}
		}
		.custom-dropdown-area{
			width: 100%;
			.custom-dropdown-button{
				font-size: rem-calc(18);
				line-height: rem-calc(21.6);
				border: none;
				box-shadow: none;
				background: none;	
			}		
			ul{
				li{
					span{
						font-size: rem-calc(18);
						line-height: rem-calc(21.6);
					}
				}
			}
		}
	}

}