
.btn{
	padding: 0 30px 0 90px;
	display: block;
	background: $blue;
	position: relative;
	color: #fff;
	text-transform: uppercase;
	border-radius: 4px;
	position: relative;
	line-height: rem-calc(40);
	margin: 30px 0;
	font-size: rem-calc(14);
	font-weight: 900;
	text-align: center;
	transition: background-color 0.3s ease;
	&.without-picto{
		margin: 7px 0 30px;
		padding: 20px 30px;
		line-height: rem-calc(19);
	}
	&.refresh{
		margin-bottom: 0;
		&.loading{
			svg{
			    -webkit-animation-name: spin;
			    -webkit-animation-duration: 4000ms;
			    -webkit-animation-iteration-count: infinite;
			    -webkit-animation-timing-function: linear;
			    -moz-animation-name: spin;
			    -moz-animation-duration: 4000ms;
			    -moz-animation-iteration-count: infinite;
			    -moz-animation-timing-function: linear;
			    -ms-animation-name: spin;
			    -ms-animation-duration: 4000ms;
			    -ms-animation-iteration-count: infinite;
			    -ms-animation-timing-function: linear;
			    
			    animation-name: spin;
			    animation-duration: 4000ms;
			    animation-iteration-count: infinite;
			    animation-timing-function: linear;			
			}
		}
	}
	&:hover, &:active, &:focus{
		background: #2b5899;
		color: #fff;
	}
	span{
		width: 50px;
		background: $grey;
		position: absolute;
		left: 0;
		top:0;
		height: 100%;
		-webkit-border-top-left-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius-topleft: 4px;
		-moz-border-radius-bottomleft: 4px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;	
		text-align: center;
		&:before{
			@include top-right(40px, 20px);
			border-color: $grey transparent transparent transparent;
		}	
		svg{
			vertical-align: middle;
			max-width: 24px;
		}
	}
}

.btn-2{
	background: #f2f2f2;
	display: block;
	padding: 15px 50px 15px 15px;
	font-size: rem-calc(14);
	font-weight: 500;
	border-left: 4px solid $blue;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #000;
	position: relative;
	&.pdf{
		svg{
			position: absolute;
			right: 15px;
			top: 50%;
			margin-top: -12px;
		}
	}
	&:hover, &:active, &:focus{
		color: $blue;
	}
}		

@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@media #{$small-up} { 

}
@media #{$small-only} { 

	.btn{
		width: 100%;
	}
}

@media #{$medium-up} { 

	.btn{
		padding-right: 30px;
		display: inline-block;
		line-height: rem-calc(60);
		font-size: rem-calc(18);
		&.without-picto{
			// padding: 10px 30px;
			line-height: rem-calc(27);
		}		
		span{
			&:before{
				@include top-right(60px, 20px);
				border-color: $grey transparent transparent transparent;				
			}
			svg{
				// vertical-align: middle;
				max-width: 100%;
			}			
		}				
	}
}
@media #{$medium-only} { 

}

@media #{$large-up} { 

}

@media #{$large-only} { 

}

@media #{$xlarge-up} { 

	
}
@media #{$xlarge-only} { 

}

@media #{$xxlarge-up} { 

}
@media #{$xxlarge-only} { 

}