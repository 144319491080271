// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$grey: #4d4f53;
$blue: #3d7edb;
$bluefade: #77a5e6;

@mixin top-right($height, $width) {
	width: 0;
	height: 0;
	border-style: solid;
	// border-width: 92px 35px 0 0;
	border-width: $height $width 0 0;
	border-color: #3d7edb transparent transparent transparent;
	position: absolute;
	right: -$width;	
	content: "";	
	top: 0;
}

@mixin bottom-left($height, $width) {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: $height $width 0px 0;
	// border-width: 160px 60px 0px 0;
	border-color: transparent #3d7edb transparent transparent;
	position: absolute;
	left: -$width;	
	content: "";
	bottom: 0;
}

@mixin bottom-arrow-after() {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 28px 0 28px;
	border-color: #3d7fdb transparent transparent transparent;
	position: absolute;
	left: 50%;
	margin-left: -28px;
	bottom: -24px;
	content: "";
}

// breakpoints
   
$S:     320px;   
$M:     768px;     
$L:     1170px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}