// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}
.aligncenter {
  display: block;
  margin: ($column-gutter / 2) auto;
}



.alignleft,
.alignright {
  margin-bottom: ($column-gutter / 2);
}
@media #{$small-up} {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($column-gutter);
  }
  .alignright {
    float: right;
    margin-left: ($column-gutter / 2);
  }

  .alignleft,
  .alignright {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

}

// Captions
.wp-caption {
  // @extend .thumbnail;
}
.wp-caption-text {
  padding: ($column-gutter / 2);
  font-size: rem-calc(12);
  line-height: rem-calc(16);
  padding-top: 5px;
  font-style: italic;
}

// Text meant only for screen readers
.screen-reader-text {
  // @extend .sr-only;
  // @extend .sr-only-focusable;
}

@media #{$medium-up} { 

}

@media #{$large-up} { 
  .page{
    .alignleft{
      margin-left: -200px;
    }
  }

  .alignleft,
  .alignright {
    display: inline-block;
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .alignleft {
    float: left;
    margin-right: ($column-gutter);
  }
  .alignright {
    float: right;
    margin-left: ($column-gutter / 2);
  }

  .wp-caption-text {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }

}