aside{
	margin-top: 50px;
	.widget{
		h3{
			color: $grey;
			font-size: rem-calc(20);
			font-weight: 900;
			border-bottom: 4px solid $blue;
			padding-bottom: 12px;
			margin-bottom: 25px;
		}
		ul:not(.f-dropdown){
			list-style-type: none;
			margin-left: 0;
			li{
				position: relative;
				padding-left: 15px;
				margin: 10px 0;
				&:before{
					content: "›";
					font-weight: 700;
					font-size: rem-calc(14);
					line-height: 1;
					color: $blue;
					position: absolute;
					left: 0;
					top: 4px;
				}
				a{
					color: $grey;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
		input,select{
			color: #808080;
		}
		::-webkit-input-placeholder { 
			color: #808080;
		}
		:-moz-placeholder { 
			color: #808080;
		}
		::-moz-placeholder { 
			color: #808080;
		}
		:-ms-input-placeholder { 
			color: #808080;
		}  	
		&.searchform{
			label{
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: rem-calc(16);
			}
			.custom-dropdown-area{
				width: 100%;
				a{
					background: none;
					position: relative;
					background: #fff;
					color: #808080;
					&:after{
						content: url(../images/dropdown-select.svg);
						position: absolute;
						width: 16px;
						height: 8px;
						right: 15px;
						top: 50%;
						margin-top: -8px;
						z-index: 10;
					}
				}
				ul{
					background: #fff;
					li{
						&.selected{
							display: none;
						}
						span{
							color: #808080;
						}
					}
				}
			}
		}
	}
}

@media #{$medium-up} { 
	aside{
		.widget{
			h3{
				color: $grey;
				font-size: rem-calc(30);
				font-weight: 900;
				border-bottom: 4px solid $blue;
				padding-bottom: 12px;
				margin-bottom: 25px;
			}
			ul:not(.f-dropdown){
				li{
					&:before{
						font-size: rem-calc(20);
						top: 0;
					}
					a{
						font-size: rem-calc(20);
						line-height: rem-calc(24);						
					}
				}
			}
		}		
	}
}
@media #{$medium-only} { 

}

@media #{$large-up} { 
	aside{
		margin-top: 0;
	}
}

@media #{$large-only} { 

}

